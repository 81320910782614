/* ==|====================
   Layout/Social Media
======================= */



.hero-container {
	display: inline-block;
	width: 100%;
}


.hero-image {
	// // flex: 0 1 auto;
	// flex-grow:1;
	float: left;
	width: 50%;
}

.hero-image:nth-child(1)  {
	padding-right: 1em;
}

.hero-image:nth-child(2) {
	padding-left: 1em;
}


