/* ==|====================
   Modules/News
   ======================= */

.c-news { 
  float: left;
  width: 100%;
  background-color: $color2;
  padding: 2em;
  margin-bottom: 2em;

  &--body {
    float: left;
    width: 100%;
    margin-bottom: 1em;
  }

  &--date {
    font-size: 0.85em;
    font-weight: bold;
  }
}
