/* ==|====================
   Modules/Gallery
   ======================= */

.c-gallery {
    padding: .5vw;
    font-size: 0;
    display: -ms-flexbox;
    flex-flow: row wrap;
    display: -webkit-box;
    display: flex;
}

.c-gallery a {
    flex: auto;
    width: 200px;
    margin: .5vw;
}

.c-gallery a img {
    width: 100%;
    height: auto;
}

//********** Breakpoints ************** //

@media only screen and (min-width: $width-tabletlandscape) {}


@media only screen and (max-width: $width-tabletlandscape) {

    .c-gallery a {
        margin: 0;
    }

    .c-gallery {
        padding: 0;
    }

}


@media only screen and (max-width: $width-tabletportrait) {}


@media only screen and (max-width: $width-smartphoneportrait) {}