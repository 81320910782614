/* ==|====================
   Component/Form
   ======================= */

.FormBuilder {

    /* basic CSS styling */
    & input.error {
        border-color: red !important;
    }

    .Inputfield {
        padding: 0;
        margin: 0 0 1em 0;
    }

    .Inputfield p.error {
        margin: 0.5em 0;
        border: 1px solid red;
        padding: 2px 5px;
    }

    // .Inputfield .row { padding: 1.618em 0 0; margin: 0 1.618em 1.618em; border-top: 1px solid #aaa; }
    .Inputfield input[type="text"],
    input[type="number"],
    .Inputfield input[type="email"],
    .mc-field-group input,
        {
        padding: 0.8em;
        background-color: #eee;
        border: none;
        width: 100%;
    }

    .Inputfield textarea,
        {
        padding: 0.8em;
        background-color: #eee;
        border: none;
        width: 100%;
    }

    .InputfieldColumnWidth {
        float: right;
        padding-left: 1em;

        @media only screen and (max-width: $width-tabletportrait) {
            width: 100% !important;
            padding: 0;
        }

        &.InputfieldColumnWidthFirst {
            float: left;
            padding-left: 0em;
        }
    }

    input[type="text"],
    input[type="number"] {
        @include border-radius(2px 0 0 2px);
        border-right: none;
    }

    .Inputfield_honingpot {
        display: none;
    }

    label {
        @include inline-block;
        margin-bottom: 0.4em;
    }

    textarea,
    .formt-text {
        @include border-radius(2px 0 0 2px);
        border-right: none;
    }

    h2 {
        text-transform: uppercase;
        font-size: 1.5em;
        margin-top: 0;
        margin-bottom: 2.5em;
    }

}


ul.InputfieldRadiosStacked {
    list-style-type: none;

    input[type="radio"] {
        margin-right: 0.5em;
    }

}

.c-contact {

    .scf-website {
        @extend .hidden;
    }

    .submitted {
        @extend .hidden;
    }
}