	/* ==|====================
Layout/Main
======================= */



/* Splash section */

.splash {
	// background: #FFF6E5;
	padding-top: 2em;
	padding-bottom: 4em;
}

.splash-content {
	padding: 1.5em 0;

	@media screen and (max-width: 1160px) {
		padding: 1.5em;
	}
}

@media screen and (min-width: 600px) {
	.splash-text {
		columns: 2;
		column-gap: 4em;
	}

	.splash-float {
		float: left;
		width: 48%;
	}

	.splash-float:nth-child(odd) {
	    float:right;
	}

	.splash-text p {
		padding-top: 0;
		margin-top: 0;
	}

	.splash-text h2 {
		margin-top: 4px;
	}
}

/* Buckets section */

.buckets {
	padding: 2em 1em 1em;
	background: #daeaed;

	&.white {
		background-color: white;
		border-top: 1px solid $color2;

		& a {
			color: black;
			text-decoration: none;
			text-transform: uppercase;
		}
	}
}

.buckets ul {
	margin: 0;
	padding: 0;
}

.buckets li {
	margin-bottom: 1em;
	background: white;
	list-style-type: none;
}

.buckets a{
	color: $color3;
	text-decoration: underline;

	&:hover {
		color: black;
	}
}

.bucket {
	padding: 1.5em;
}

/* More section */

.more {
	padding: 2em;
}

/* Twins section */

.twin {
	padding: 4em 8em;
	background: $color2;
	color: black;

	@media screen and (max-width: 1120px) {
		padding: 1.5em;
	}
}

.twin.triangle {
	position: relative;
	background: $color3;
}

.twin.triangle::before{
	display: block;
	position: absolute;
	display: none;
	content: '';
	top: 50%;
	left: -15px;
	transform: translateY(-50%);
	border-right: 18px solid $color3;
	border-top: 15px solid transparent;
	border-bottom: 18px solid transparent;
	z-index: 1;
}

.twin.bgimg {   
	height: 600px;
    background-position: center !important;
    background-repeat: no-repeat !important;
    background-size: cover !important;
}

/* Colophon section */
.colophon {
	padding: 2em;
	background: hsl(0, 0%, 10%);
	color: white;
	text-align: center;
}

.colophon a {
	color: white;
}

.colophon a:hover,
.colophon a:focus {
	border-bottom: 1px solid white;
}


.separator {
	grid-column: 2/4;
	position: relative;
	display: block;
	height: 1px;
	border-bottom: 1px solid #b2b2b2;
}

.mid-area {
	margin-bottom:4em;
}



/*--------------------------------------------------------------
Use flex to create a three-bucket layout 
--------------------------------------------------------------*/

@media screen and (min-width: 700px) {
	@supports (display: flex) {

		.buckets ul {
			display: flex;
			justify-content: space-around;
		}

		.buckets li {
			width: 31%;
		}

	}
}

/*--------------------------------------------------------------
CSS Grid layout for wider screens, when browser supports grid:
--------------------------------------------------------------*/

@media screen and (min-width: 600px) {
	/* Layout with CSS Grid */
	@supports (display: grid) {

		/* Center items by placing them in the two center columns: */
		.splash-content,
		.more-content,
		.buckets ul {
			grid-column: 2/4;
		}

		/* Use automatic grid placement + span to let each item span two columns:*/
		.twin,
		.colophon aside {
			grid-column: span 2;
		}

	}
}


