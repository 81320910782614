/* ==|====================
   Layout/Grid
   ======================= */


/*--------------------------------------------------------------
If no grid support, limit width to 50em and center align
--------------------------------------------------------------*/
@supports not (display: grid) {
    .grid {
        max-width: 50em;
        margin: 0 auto;
    }
}


/*--------------------------------------------------------------
CSS Grid layout for wider screens, when browser supports grid:
--------------------------------------------------------------*/

@media screen and (min-width: 600px) {

    /* Layout with CSS Grid */
    @supports (display: grid) {

        /* Four-column layout. Two center columns have a total max width of 35em: */
        .grid {
            display: grid;
            grid-template-columns: 1fr repeat(2, minmax(auto, 35em)) 1fr;
        }

        .l-constrained {
            grid-column: 2/4;
        }

    }
}




//********** Breakpoints ************** //

@media only screen and (min-width: $width-tabletlandscape) {}


@media only screen and (max-width:$width-tabletlandscape) {}


@media only screen and (max-width:$width-tabletportrait) {}


@media only screen and (max-width:$width-smartphoneportrait) {}