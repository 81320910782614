/* ==|====================
   Layout/Nav
   ======================= */

blockquote {
  position: relative;
  padding-left: 1em;
  border-left: 0.2em solid lighten(black, 40%);
  font-weight: 100;
  font-size: 2em;
  &:before, &:after {
      content: '\201C';
      font-family: $font1;
      color: lighten(black, 40%);
   }
   &:after {
      content: '\201D';
   }
}