/* ===========================
   Base
   =========================== */

body {
    font-family: $font1;
    font-weight: 300;
    font-size: 1em;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
}

a {
    color: $color-link;
    text-decoration: none;
    @include transition();

    &:hover {
        color: $color-link-hover;
    }
}

img, embed, object, video {
    max-width: 100%;
    opacity: 1;
    transition: opacity 1s ease-in-out 0s;
}

::-moz-selection {
    background: $color1;
    color: #fff;
    text-shadow: none;
}
::selection {
    background: $color1;
    color: #fff;
    text-shadow: none;
}

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid #ccc;
    margin: 1em 0;
    padding: 0;
}