/* ==|====================
   Module/Headings
   ======================= */

%base-heading {
    font-family: $font2;
    // text-transform: uppercase;
    font-weight: normal;
    color: $color1;
}



h1 {
    @extend %base-heading;
    letter-spacing: -2px;
    font-size: 2em;
    margin-bottom: 2em;
    line-height: 1;

    // margin: 5em 0 1em; 

    @media only screen and (max-width: $width-tabletportrait) {
        font-size: 1.5em;
    }
}


h2 {
    @extend %base-heading;
    // letter-spacing: -1px;
    font-size: 1.4em;
    line-height: 100%;
    // margin-bottom: 2em;

    @media only screen and (max-width: $width-tabletportrait) {
        font-size: 1.2em;
    }

}


h3 {
    @extend %base-heading;

    font-size: 1.4em;
    line-height: 100%;

    @media only screen and (max-width: $width-tabletportrait) {
        font-size: 1.2em;
    }
}


h4 {
    @extend %base-heading;
    font-size: 1.4em;
    line-height: 100%;

    @media only screen and (max-width: $width-tabletportrait) {
        font-size: 1.2em;
    }

}


.content-title {
    text-transform: uppercase;
}