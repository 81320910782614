/* ==|====================
   Module/Icons
   Documentation : https://codepen.io/noahblon/pen/qEByrR
   ======================= */


@function _buildIcon($icon) {
  $icon: '<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24" height="24">#{$icon}</svg>';
  @return $icon;
}

@function icon(
  $icon-name, 
  $color, 
  $stroke-color: transparent,
  $stroke-width: 0,
  $css: '' // arbitrary css
  ){
  $icons: (
    facebook: '<path fill="#{$color}" stroke="#{$stroke-color}" stroke-width="#{$stroke-width}" style="#{$css}" d="M22.676,0l-21.352,0c-0.731,0 -1.324,0.593 -1.324,1.324l0,21.352c0,0.732 0.593,1.324 1.324,1.324l11.494,0l0,-9.294l-3.129,0l0,-3.621l3.129,0l0,-2.675c0,-3.099 1.894,-4.785 4.659,-4.785c1.325,0 2.464,0.097 2.796,0.141l0,3.24l-1.921,0c-1.5,0 -1.792,0.721 -1.792,1.771l0,2.311l3.584,0l-0.465,3.63l-3.119,0l0,9.282l6.115,0c0.733,0 1.325,-0.592 1.325,-1.324l0,-21.352c0,-0.731 -0.592,-1.324 -1.324,-1.324"/>',
    instagram: '<path fill="#{$color}" stroke="#{$stroke-color}" stroke-width="#{$stroke-width}" d="M11.984 16.815c2.596 0 4.706-2.111 4.706-4.707 0-1.409-.623-2.674-1.606-3.538-.346-.303-.735-.556-1.158-.748-.593-.27-1.249-.421-1.941-.421s-1.349.151-1.941.421c-.424.194-.814.447-1.158.749-.985.864-1.608 2.129-1.608 3.538 0 2.595 2.112 4.706 4.706 4.706zm.016-8.184c1.921 0 3.479 1.557 3.479 3.478 0 1.921-1.558 3.479-3.479 3.479s-3.479-1.557-3.479-3.479c0-1.921 1.558-3.478 3.479-3.478zm5.223.369h6.777v10.278c0 2.608-2.114 4.722-4.722 4.722h-14.493c-2.608 0-4.785-2.114-4.785-4.722v-10.278h6.747c-.544.913-.872 1.969-.872 3.109 0 3.374 2.735 6.109 6.109 6.109s6.109-2.735 6.109-6.109c.001-1.14-.327-2.196-.87-3.109zm2.055-9h-12.278v5h-1v-5h-1v5h-1v-4.923c-.346.057-.682.143-1 .27v4.653h-1v-4.102c-1.202.857-2 2.246-2 3.824v3.278h7.473c1.167-1.282 2.798-2 4.511-2 1.722 0 3.351.725 4.511 2h7.505v-3.278c0-2.608-2.114-4.722-4.722-4.722zm2.722 5.265c0 .406-.333.735-.745.735h-2.511c-.411 0-.744-.329-.744-.735v-2.53c0-.406.333-.735.744-.735h2.511c.412 0 .745.329.745.735v2.53z"/>',
    circles: '<g stroke="green" fill="white" stroke-width="5"><circle cx="25" cy="25" r="15"/><circle cx="40" cy="25" r="15"/><circle cx="55" cy="25" r="15"/><circle cx="70" cy="25" r="15"/></g>'
  );
  
  $icon: _buildIcon(map-get($icons, $icon-name));
  @return url('data:image/svg+xml;utf8,#{$icon}');
}


.icon {
  display: inline-block;
  font-size: 0;
  width: 24px;
  height: 24px;
  margin: 0 auto;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
}

.icon-lg {
  width: 75px;
  height: 75px;
}

.icon-facebook { background-image: icon(facebook, gray); }
.icon-instagram { background-image: icon(instagram, gray); }
.icon-circles { background-image: icon(circles, gray); }


.icon-facebook-stroke { background-image: icon(facebook, transparent, blue, 1); }
.icon-instagram-stroke { background-image: icon(instagram, transparent, blue, 1); }
