/* ==|====================
   Layout/Nav
   ======================= */

.l-nav {

    &__first {

        padding: 1em;

        .logo {
            grid-column: 2/3;
            width: 220px;
            align-self: center;
        }

    }


    &__second {

        background-color: #f4f2f2;
        // padding: 1em;
        text-align: center;
        margin-bottom: 2em;

        &>ul {
            grid-column: 2/4;
            padding: 0;
        }

    }


}

#menumobile {
    float: left;
    width: 100%;
}

.slicknav_menu {
    display: none;
}

@media only screen and (max-width: 840px) {

    /* #menu is the original menu */
    #menu {
        display: none;
    }

    .slicknav_menu {
        display: block;
        background: none !important;
        text-align: left;
    }

    .slicknav_nav a {
        color: $color1 !important;
    }

    .slicknav_nav li {
        border-bottom: 1px solid white !important;
    }
}


/*==========================================================================
   Menu Primary
   http://osvaldas.info/drop-down-navigation-responsive-and-touch-friendly
========================================================================== */

.l-nav__second.ul-list-inline {
    @include inline-block();
    float: left;
}


.l-nav__second .right {
    float: right;
    margin-right: 0;
    text-align: right;

    ul.sub-menu li {
        text-align: left;
    }
}



/* Eerste niveau van het menu */
ul.mainnav {
    display: block;
    text-transform: uppercase;
    padding: 0;
    font-size: 1.1em;



    @media only screen and (max-width: 1100px) {
        font-size: 0.95em;
    }

    @media only screen and (max-width: 945px) {
        font-size: 0.85em;
    }

    @media only screen and (max-width: 768px) {
        font-size: 0.75em;
    }

    & li {
        @include inline-block();
        position: relative;
        z-index: 300;
        margin-right: 12px;
    }

    & li a {
        color: #000;
        font-size: 13px;
        line-height: 1.3;
        font-weight: 400;
        letter-spacing: .1em;
        font-family: "Montserrat", Helvetica, Arial, sans-serif;
        text-transform: uppercase;
        display: block;
        color: $color-link;
        @include transition(all 0.2s ease-in-out 0s);
    }

    &>li>a {
        padding: 1.5em 0;
    }
}

ul.mainnav>li {
    width: auto;
    margin-right: 20px;
}


ul.mainnav>li:hover,
ul.mainnav>li.active {
    // border-bottom: 2px solid black;
}

ul.mainnav>li:last-child {
    margin-right: 0;
}

ul.mainnav li a:hover {
    color: $color-link-hover;
}

/* Tweede niveau van het menu*/
.mainnav ul {
    position: absolute;
    width: 300px;
    left: 0px;
    display: none;
    margin: 0;
    padding: 0;
}

ul.sub-menu {
    margin-left: 0px;
    padding-top: 10px;
}


ul.sub-menu ul {
    padding-left: 5px;
}

/* Bij mouseover tweede niveau tonen */
.mainnav li:hover>ul {
    display: block;
}

.mainnav ul li {
    display: block;
    float: left;
    background: white;
    margin: 0 0 5px 0;
    padding: 5px 0;
}

.mainnav ul li a {
    font-size: 0.85em;
    font-weight: normal;
    display: block;
    color: black;
    background: white;
    padding-left: 10px;
    padding-right: 20px;
}

.mainnav ul li a:hover,
.mainnav ul li:hover>a {
    background: none repeat scroll 0 0 $color-link-hover;
    color: white;
}

/* Derde niveau van het mnu */
.mainnav ul ul {
    left: 300px;
    top: 0px;
    padding-top: 0px;
}




/* ==========================================================================
   Menu Breadcrumbtrail
==========================================================================*/

ul.breadcrumb {
    padding-bottom: 0.5em;
    display: none;
}


ul.breadcrumb>li {
    font-size: 0.75em;
    margin-right: 1em;
}