/* ==|====================
   Module/Border
   http://stackoverflow.com/questions/11765642/sass-optimize-borders-into-one-line-of-code
   ======================= */

.brd {
    @include border-radius(0px);
}

@mixin brd( $coordinates: 0 0 0 0, $colour: #000000, $style: solid ) {

  @extend .brd;
    
  $top: nth($coordinates, 1);
  $right: nth($coordinates, 2);
  $bottom: nth($coordinates, 3);
  $left: nth($coordinates, 4);

  @if not(unitless($top)) {
    border-top: $top $style $colour;
  }
  @if not(unitless($right)) {
    border-right: $right $style $colour;
  }
  @if not(unitless($bottom)) {
    border-bottom: $bottom $style $colour;
  }
  @if not(unitless($left)) {
    border-left: $left $style $colour;
  }

}


.brd-right {
    @include brd(0 1px 0 0, $color1);
}

.brd-bottom {
    @include brd(0 0 8px 0, $color1);
    padding-bottom: 4px;
    margin-bottom: 12px;
}