/* ==|====================
   Module/Button
   ======================= */

.btn {

    @include border-radius(0px);
    @include inline-block();
    
    text-align: center;
    margin-top: 1em;
    vertical-align: middle;
    cursor: pointer;
    color: white;

        &:after {
        display: inline-block;
        content: '\00bb';
        margin: 0 .6em;
        color: white;
    }
}

@mixin btn($c1, $c2, $c3:none, $c4:2px) {
    @extend .btn;
    padding: .4em .7em;
    color: $c1;
    border-width: $c4;
    border-style: solid;
    border-color: $c1;
    text-shadow: 0 1px 1px rgba(#000, 0.1);
    // float: left;
    background-color: $c3;

    &:hover {
        background-color: $c2;
        color: white;
        border-color: $c2;
    }

    &:after {
        display: inline-block;
        content: '\00bb';
        margin: 0 .6em;
        color: $c1;
    }
}


.btn-border--c1 {
    @include btn($color1, $color2 );
}

.btn-border--c2 {
    @include btn($color2, $color1);
}

.btn-border--w {
    @include btn(white, $color1);
}

button {
    @include btn(black, $color3, $color2, 0px );
    margin-bottom: 3em;
    text-transform: uppercase;
    letter-spacing: 4px;
}

