/*
 * Inspired by SMACSS http://smacss.com & https://github.com/jonathanpath/SASS-SMACSS
 * Sass Structure inspired by : https://github.com/sutter/POPY-starter-HTML-SCSS
 * Coding rules : https://github.com/necolas/idiomatic-css
 */

/* Settings */
@import 'base/site-settings';

/* Tools */
@import 'tools/mixins';
@import 'tools/functions';
@import 'tools/helpers';

/* Breakpoint */
// @import "../bower_components/compass-breakpoint/stylesheets/breakpoint";

/* Base */
@import 'base/site-settings';
@import 'base/normalize';
@import 'base/base';
@import 'base/form';

/* Fonts */

/* Layout */
@import 'layout/main';
@import 'layout/header';
@import 'layout/footer';
@import 'layout/nav';
@import 'layout/quote';
@import 'layout/footer';
@import 'layout/grid';
@import 'layout/cards';
@import 'layout/consent';

/* Components */
@import 'component/icons';
@import 'component/btn';
@import 'component/headings';
@import 'component/brd';
@import 'component/table';
@import 'component/form';
@import 'component/wysiwyg';
@import 'component/modal';
@import 'component/menu';
@import 'component/social';
@import 'component/slideshow';
@import 'component/gallery';
@import 'component/news';
@import 'component/map';

/* States */
@import 'state';
