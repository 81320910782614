.is-uppercase{
	text-transform: uppercase;
}

.has-nomargin {
	margin: 0;
}

.has-nopadding{
	padding: 0;
}

.has-nmt {
	margin-top: 0;
}

.has-nmb {
	margin-bottom: 0;
}

.has-mt-1 {
	margin-top: 1em;
}

.has-mb-1 {
	margin-bottom: 1em;
}

.has-mtb-1 {
	margin-top: 1em;
	margin-bottom: 1em;
}
