/* ==|====================
   Base/Form
   ======================= */

input[type="text"],
input[type="email"],
input[type="password"],
input[type="date"],
input[type="search"],
textarea {
    font-size: 1em;
    font-family: $font1;
    font-weight: 300;
    color: #666;
    padding: .5em;
    border: 1px solid #bfbfbf;
    outline: 0;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    background-color: #f7f7f7;
    @include placeholder($color-mixin-placeholder);

    &:hover {
        border-color: #a5a5a5;
    }

    &:focus {
        border-color: #999;
    }
}