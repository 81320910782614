/* ==|===============
   Module/Text formatted (rich content)
   ================== */

.wysiwyg {
    h1 {
        text-align: left;
        color: $color1;
        font: 3em/1.5;
        border-bottom: 1px solid $color2;
        padding-bottom: 0.5em;
        width: 70%;
        letter-spacing: 2px;

        @media screen and (max-width: 840px) {
            font-size: 1.4em;
        }
    }


    h2 {
        color: $color1;    
    }

    p {
        text-align: justify;
        line-height: 1.5;
    }

    a {
        color: #a4ba46;
        text-decoration: underline;

        &:hover {
            color: black;
        }
    }
}