/* ==|====================
   Layout/Social Media
======================= */


.c-social {
	grid-column: 3/4;
	justify-self: end;
	text-align: right;

	& > a {
		padding: 0 0.3em;
		
	}
}