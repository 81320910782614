/* ==|====================
   Layout/Cards
   ======================= */

.cards {
    grid-column: 2/4;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    & .card {
        // flex: 0 1 calc(25% - 1em);
        background: $color2;
        margin-bottom: 2em;
    }

    & .card a {
        color: black;
        text-decoration: none;
    }

    & .card a:hover {
        box-shadow: 3px 3px 8px hsl(0, 0%, 80%);
    }

    & .card-content {
        padding: 1.4em;
    }

    & .card-content h2 {
        margin-top: 0;
        margin-bottom: .5em;
        // font-weight: bold;
    }

}


//********** Breakpoints ************** //

@media only screen and (min-width: $width-tabletlandscape) {

    .card {
        flex: 0 1 calc(25% - 1em);
    }

}


@media only screen and (max-width: $width-tabletlandscape) {

    .card {
        flex: 0 1 calc(25% - 1em);
    }

}


@media only screen and (max-width: $width-tabletportrait) {

    .card {
        flex: 0 1 calc(50% - 1em);
    }

}


@media only screen and (max-width: $width-smartphoneportrait) {

    .card {
        flex: 0 1 100%;
    }

}