.c-map {
	height: 400px;

	& .title {
		font-weight: bold;
	}

	& a {
		text-decoration: underline;
	}
}